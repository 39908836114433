export const config = {
    app: {
        host: "https://demo.bonbon.dev",
        port: 3000
    },
    api: {
        host: "https://demo.bonbon.dev/api",
        port: 3000
    },
    kvk: {
        host: "https://demo.bonbon.dev/kvk",
        port: 3000
    },
    payments: {
        host: "https://demo.bonbon.dev/payments",
        port: 3000
    },
    snelstart: {
        host: "https://demo.bonbon.dev/snelstart",
        port: 3000
    },
    ws: {
        host: "wss://demo.bonbon.dev/ws",
        port: 3001
    },
    webpack: {
        host: "https://demo.bonbon.dev:8080",
        port: 8080
    },
    token: "AIzaSyCdYmiOMYeEl-35AJeu5s12jEHbuVofhQU"
}
